<template>
  <div class="w-100">
    <save-brand ref="brand_modal"></save-brand>
    <div class="view_inner d-flex flex-column">
      <div class="view_center">
        <div class="steps_dot mb-3">
          <span class="dotted_circle active"></span>
          <span class="dotted_circle"></span>
        </div>


        <b-dropdown right ref="select_brand_campaign_dropdown" class=" dropdown-menu-right default_style_dropdown "
                    :no-caret="true">
          <div @click="fetchBrandOnDropdown"
               class="dropdown_header with-shadow d-flex align-items-center rounded_shape  "
               slot="button-content" data-cy="select-brand">
            <span class="text">
            <template>{{ getSelectedBrand() }}</template>
          </span>
            <span class="arrow_icon ml-auto"><i class=" fal fa-angle-down ml-3"></i></span>
          </div>
          <ul id="brand-dropdown-scroll" @scroll="lazyScrollBrand('brand-dropdown-scroll','fetchBrandList',1)"
              class="inner">
            <template v-if="getBrandList && getBrandList.length && getBrandList.length > 7 || getBrand.search">
              <div class="mr-2 mb-2 mt-2 search_input_block search_input_block_for_dropdown no_animation d-flex">
                <input placeholder="Search for brand"
                       type="text"
                       class="search_input_block_for_dropdown" @input="debounceBrandDropdownSearch"
                       :value="getBrand.search"> <i
                class="fal fa-search"></i>
              </div>
              <hr>
            </template>
            <template v-if="getBrandList && getBrandList.length">
              <li v-for="brand in getComponentBrandList" @click.prevent="selectBrandItem(brand._id)"
                  class="list_item_li"
                  data-cy="brand-name">
                <div class="d-flex align-items-center profile__box">
                  <div class="avatar">
                    <div class="brand_img " :style="brandImageLink(brand.avatar)"></div>
                  </div>
                  <div class="content">
                    <div class="name">
                      {{ limitTextLength(brand.name, 34) }}
                    </div>
                  </div>
                </div>
              </li>
              <li v-if="getBrandLoaders.retrieve" class="list_item_li">
                <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
              </li>
            </template>
            <li v-else-if="getBrandLoaders.retrieve" class="list_item_li">
              <clip-loader :color="'#168eea'" :size="'16px'"></clip-loader>
            </li>
            <li v-if="!getBrandLoaders.retrieve && !getComponentBrandList.length && getBrand.search.length"
                class="no_text_li">{{ searchFiltersMessages('').search_field_dropdown }}
            </li>
            <template v-else-if="!getBrandLoaders.retrieve && !getBrandList.length">
              <li v-if="getProfile && getProfile.policy && getProfile.policy.can_add_brands" class="no_text_li">
                {{ searchFiltersMessages('brand').empty_list_create_new_dropdown }}
              </li>
            </template>
          </ul>
        </b-dropdown>

        <div class="or_separator" v-if="getProfile && getProfile.policy && getProfile.policy.can_add_brands">
          <span class="sep_text">OR</span>
        </div>

        <div class="text-center" v-if="getProfile && getProfile.policy && getProfile.policy.can_add_brands">
          <button class="d-block btn---cta m-auto btn-border btn-round  btn-bold" @click="saveBrand($refs.brand_modal)">
            <span>Create A New Brand</span>
          </button>
        </div>

      </div>
      <div class="view_bottom">
        <div class="view_bottom_inner text-center">
          <template v-if="getCampaignAdd.brand_id">
            <button @click="$router.push({ 'name': 'campaign_type' })"
                    class="btn---cta btn-blue btn-round">
              <span>Next</span></button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>



</template>
<style lang="less" scoped>

</style>

<script>

import Dropdown from '@/ui/Dropdown.vue'

import {mapActions, mapGetters} from 'vuex'
import {campaignTypes} from '@/state/modules/mutation-types'
import {searchFiltersMessages} from '@/common/attributes'
import SaveBrand from "@/views/pages/brands/SaveBrand";

export default ({

  components: {
    Dropdown,
    SaveBrand
  },

  data () {
    return {
      search_query: '',
      searchFiltersMessages: searchFiltersMessages
    }
  },

  async created () {
    if (this.getDomains.items && this.getDomains.items.length == 0) {
      await this.fetchDomains()
    }
    if (this.getPixelList && this.getPixelList.length == 0) {
      await this.fetchPixels()
    }
    if (this.getCustomWidgetList && this.getCustomWidgetList == 0) {
      this.fetchWidgets()
    }
  },

  computed: {

    ...mapGetters([
      'getCampaignAdd',
      'getComponentBrandList',
      'getDomains',
      'getPixelList',
      'getCustomWidgetList',
      'getBrand',
      'getBrandLoaders',
      'getComponentCampaignList',
      'getProfile',
      'getBrandList'
    ])
  },

  methods: {

    ...mapActions(['fetchBrandList', 'fetchDomains', 'fetchPixels', 'fetchWidgets']),
    selectBrandItem (brand) {
      console.debug('Method::selectBrandItem > ', brand)
      this.$store.commit(campaignTypes.SET_CAMPAIGN_ADD_BRAND, brand)
      this.$refs.select_brand_campaign_dropdown.visible = false
      this.getSelectedBrand()
      // this.$router.push({ name: 'campaign_type' })
    },
    /**
     *  getting the selected brand name
     * @returns {string|*|string}
     */
    getSelectedBrand () {

      // if creating new rss so cta_id is null
      if (!this.getCampaignAdd.brand_id) {
        return 'Select your brand'
      }

      // while editing rss so ge campaign name from list if not then get from rss feed list
      const brandName = this.getBrandById(this.getCampaignAdd.brand_id)
      if (!brandName) {
        const brandObj = this.getComponentCampaignList.find(item => this.getCampaignAdd.brand_id === item.brand_id)
        return brandObj.brand && brandObj.brand.name ? brandObj.brand.name : 'Select your Brand'
      }

      // is selected campaign exists then simple returs its name
      return brandName && brandName.name ? brandName.name : 'Select your Brand'

    }
  },
  watch: {
    'getCampaignAdd.brand_id' (value) {
      this.getSelectedBrand()
    }
  }

})
</script>
